/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FleetsListData,
  FleetsVessel,
  FleetsVesselBasic,
  FleetVesselInfo,
} from '../fleets.model';

interface SelectedFleet {
  listName: string;
  selectedList: FleetsListData;
  vessels: FleetsVessel[];
}

export interface FleetColouredByVesselType {
  vessel_list_id: string;
  vessel_ids: string[];
}

export interface FleetColouredBySelectedColour {
  vessel_list_id: string;
  colour: string;
  vessel_ids: string[];
}

interface ManageFleetsState {
  selectedFleet: SelectedFleet | null;
  listLoading: boolean;
  filterValue: string;
  checkedVesselsIds: string[];
  isCreateFleetDialogOpen: boolean;
  confirmFleetVesselsOpen: boolean;
  confirmFleetVessels: FleetsVesselBasic[];
  amendFleetVessels: FleetVesselInfo[];
  manageFleetVesselsLoading: boolean;
  manageFleetVesselsLoadingMessage: string;
  manageFleetVesselsError: boolean;
  manageFleetVesselsErrorMessage: string;
  manageFleetVesselsSuccess: boolean;
  manageFleetVesselsSuccessMessage: string;
  isCompanyFleet: boolean;
  explain: boolean;
  fleetColouredByVesselType: FleetColouredByVesselType[];
  fleetColouredBySelectedColour: FleetColouredBySelectedColour[];
  createdFleetId: string;
  createdDynamicFleetName: string;
}

export const INITIAL_MANAGE_FLEETS_STATE: ManageFleetsState = {
  selectedFleet: null,
  listLoading: true,
  filterValue: '',
  checkedVesselsIds: [],
  isCreateFleetDialogOpen: false,
  confirmFleetVesselsOpen: false,
  confirmFleetVessels: [],
  manageFleetVesselsLoading: false,
  manageFleetVesselsLoadingMessage: 'Loading...',
  manageFleetVesselsError: false,
  manageFleetVesselsErrorMessage: '',
  manageFleetVesselsSuccess: false,
  manageFleetVesselsSuccessMessage: '',
  isCompanyFleet: false,
  explain: false,
  fleetColouredByVesselType: [],
  fleetColouredBySelectedColour: [],
  amendFleetVessels: [],
  createdFleetId: '',
  createdDynamicFleetName: '',
};

const ManageFleetsSlice = createSlice({
  name: 'manageFleetsSlice',
  initialState: INITIAL_MANAGE_FLEETS_STATE,
  reducers: {
    setSelectedFleet(state, action: PayloadAction<SelectedFleet | null>) {
      state.selectedFleet = action.payload;
    },
    setListLoading(state, action: PayloadAction<boolean>) {
      state.listLoading = action.payload;
    },
    setFilterValue(state, action: PayloadAction<string>) {
      state.filterValue = action.payload;
    },
    setCheckedVesselsIds(state, action: PayloadAction<string[]>) {
      state.checkedVesselsIds = action.payload;
    },
    setIsCreateFleetDialogOpen(state, action: PayloadAction<boolean>) {
      state.isCreateFleetDialogOpen = action.payload;
    },
    setConfirmFleetVesselsOpen(state, action: PayloadAction<boolean>) {
      state.confirmFleetVesselsOpen = action.payload;
    },
    setConfirmFleetVessels(state, action: PayloadAction<FleetsVesselBasic[]>) {
      state.confirmFleetVessels = action.payload;
    },
    setAmendFleetVessels(state, action: PayloadAction<FleetVesselInfo[]>) {
      state.amendFleetVessels = action.payload;
    },
    setIsCompanyFleet(state, action: PayloadAction<boolean>) {
      state.isCompanyFleet = action.payload;
    },
    setManageFleetVesselsLoading(state, action: PayloadAction<boolean>) {
      state.manageFleetVesselsLoading = action.payload;
    },
    setManageFleetVesselsLoadingMessage(state, action: PayloadAction<string>) {
      state.manageFleetVesselsLoadingMessage = action.payload;
    },
    setManageFleetVesselsError(state, action: PayloadAction<boolean>) {
      state.manageFleetVesselsError = action.payload;
    },
    setManageFleetVesselsErrorMessage(state, action: PayloadAction<string>) {
      state.manageFleetVesselsErrorMessage = action.payload;
    },
    setManageFleetVesselsSuccess(state, action: PayloadAction<boolean>) {
      state.manageFleetVesselsSuccess = action.payload;
    },
    setManageFleetVesselsSuccessMessage(state, action: PayloadAction<string>) {
      state.manageFleetVesselsSuccessMessage = action.payload;
    },
    setExplain(state, action: PayloadAction<boolean>) {
      state.explain = action.payload;
    },
    resetFleetState() {
      return INITIAL_MANAGE_FLEETS_STATE;
    },
    setCreatedFleetId(state, action: PayloadAction<string>) {
      state.createdFleetId = action.payload;
    },
    setFleetColouredByVesselType(
      state,
      action: PayloadAction<FleetColouredByVesselType[]>
    ) {
      state.fleetColouredByVesselType = action.payload;
    },
    addFleetColouredByVesselType(
      state,
      action: PayloadAction<FleetColouredByVesselType>
    ) {
      const currentState = state.fleetColouredByVesselType;
      const newState = currentState.concat(action.payload);
      state.fleetColouredByVesselType = newState;
    },
    removeFleetColouredByVesselType(state, action: PayloadAction<string>) {
      const currentState = state.fleetColouredByVesselType;
      const newState = currentState.filter(
        (fleet) => fleet.vessel_list_id !== action.payload
      );
      state.fleetColouredByVesselType = newState;
    },
    setFleetColouredBySelectedColour(
      state,
      action: PayloadAction<FleetColouredBySelectedColour[]>
    ) {
      state.fleetColouredBySelectedColour = action.payload;
    },
    addFleetColouredBySelectedColour(
      state,
      action: PayloadAction<FleetColouredBySelectedColour>
    ) {
      const currentState = state.fleetColouredBySelectedColour;
      const removeExisting = currentState.filter(
        (fleet) => fleet.vessel_list_id !== action.payload.vessel_list_id
      );
      const newState = removeExisting.concat(action.payload);
      state.fleetColouredBySelectedColour = newState;

      state.fleetColouredByVesselType = state.fleetColouredByVesselType.filter(
        (fleet) => fleet.vessel_list_id !== action.payload.vessel_list_id
      );
    },
    removeFleetColouredBySelectedColour(state, action: PayloadAction<string>) {
      const currentState = state.fleetColouredBySelectedColour;
      const newState = currentState.filter(
        (fleet) => fleet.vessel_list_id !== action.payload
      );
      state.fleetColouredBySelectedColour = newState;
    },
    setCreatedDynamicFleetName(state, action: PayloadAction<string>) {
      state.createdDynamicFleetName = action.payload;
    },
  },
});

export const {
  setSelectedFleet,
  setListLoading,
  setFilterValue,
  setCheckedVesselsIds,
  setIsCreateFleetDialogOpen,
  setConfirmFleetVesselsOpen,
  setConfirmFleetVessels,
  setManageFleetVesselsLoading,
  setManageFleetVesselsLoadingMessage,
  setManageFleetVesselsError,
  setManageFleetVesselsErrorMessage,
  setManageFleetVesselsSuccess,
  setManageFleetVesselsSuccessMessage,
  setIsCompanyFleet,
  setExplain,
  resetFleetState,
  setFleetColouredByVesselType,
  addFleetColouredByVesselType,
  removeFleetColouredByVesselType,
  setFleetColouredBySelectedColour,
  addFleetColouredBySelectedColour,
  removeFleetColouredBySelectedColour,
  setAmendFleetVessels,
  setCreatedFleetId,
  setCreatedDynamicFleetName,
} = ManageFleetsSlice.actions;

export default ManageFleetsSlice.reducer;
