import {
  applyVesselsFilter,
  getVesselsWithLocationsForFleet,
} from '../../../api';
import { postVesselList } from '../../../api/admin/vessel-lists';
import {
  convertVesselDataToFleetsEndpointValues,
  getFleetsVessels,
  getImoMmsiVessels,
  postFleetsVessels,
} from '../../../api/fleets';
import {
  getDynamicVesselsDAAS,
  getDynamicVesselsDAASWithLocation,
} from '../../../api/vessels';
import {
  setSelectedVessels,
  toggleSelectedVessel,
} from '../../../dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import { useAppDispatch } from '../../../hooks';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import clearSelectedVessel from '../../../map/map-layer-manager/vessel-utils/clear-selected-vessel';
import { updateMyFleetVesselLocationInStore } from '../../../map/map-layer-manager/vessel-utils/panel-vessel-onClick';
import setVesselFeatures from '../../../map/map-layer-manager/vessel-utils/set-vessel-features';
import MapHelpers from '../../../map/map.utils';
import {
  GlobalAisShipType,
  globalAisToDaasShipType,
} from '../../../models/vessels/all-ship-types';
import { VesselData } from '../../../models/vessels/maritime-ais-api';
import { Vessel, VesselSource } from '../../../models/vessels/vessel.model';
import { addHistoryItem } from '../../../nav-history.slice';
import {
  setFleetsVessels,
  setFleetsVesselsError,
  setFleetsVesselsLoading,
} from '../../../state/fleet-vessels/fleet-vessels.slice';
import { clearVesselHistoryData } from '../../../state/history/history.slice';
import store from '../../../store';
import { MyFleetVessel } from '../../my-fleet-panel/myFleetVessels.model';

import {
  addVesselsToCompanyFleet,
  addVesselsToPersonalFleet,
  protectCompanyFleet,
  setCompanyFleets,
  setFleetsFilters,
  setPersonalFleets,
  setVisibleVessels,
  toggleFleetVisibility,
  updateCurrentFleetNames,
} from '../fleets-panel.slice';
import {
  FleetsFilters,
  FleetsFormItem,
  FleetsListData,
  FleetsListVessel,
  FleetsVessel,
  FleetsVesselBasic,
  FleetType,
  FleetVisibility,
  NormalisedFleetsVessels,
  PatchFleetsVesselsResponse,
  SelectedFleet,
  VesselListOwnershipType,
} from '../fleets.model';
import { FleetStatusMessages } from './create-fleets/create-fleet.enum';
import DeleteFleetsDialogUtils from './delete-fleets/delete-fleets-dialog.utils';
import {
  setCompletedFetchLists,
  setFleetsLoading,
  setFleetVesselsLoading,
} from './fleets-list-context/fleets-list-context.slice';
import {
  setCheckedVesselsIds,
  setConfirmFleetVessels,
  setConfirmFleetVesselsOpen,
  setCreatedDynamicFleetName,
  setManageFleetVesselsError,
  setManageFleetVesselsErrorMessage,
  setManageFleetVesselsLoading,
  setManageFleetVesselsLoadingMessage,
  setManageFleetVesselsSuccess,
  setSelectedFleet,
} from './manage-fleets.slice';

// Function to handle multi-select of vessels
export function handleMultiSelect(
  vessel: MyFleetVessel,
  set: Set<string>,
  dispatch: ReturnType<typeof useAppDispatch>
) {
  if (set.has(vessel.vessel_id)) {
    set.delete(vessel.vessel_id);
    dispatch(clearVesselHistoryData());
    dispatch(toggleSelectedVessel(vessel));
    // If the set is now empty, clear vessel history data and set the dossier to NOTHING
    if (set.size === 0) {
      dispatch(clearVesselHistoryData());
      dispatch(setSelectedDossier(EDossiers.NOTHING));
    }
  } else {
    // otherwise toggle state of vessel and set the dossier to VESSEL
    dispatch(toggleSelectedVessel(vessel));
    dispatch(setSelectedDossier(EDossiers.VESSEL));
  }
}

// Function to handle single-select of vessels
export function handleSingleSelect(
  vessel: MyFleetVessel,
  set: Set<string>,
  dispatch: ReturnType<typeof useAppDispatch>
) {
  if (set.has(vessel.vessel_id)) {
    set.delete(vessel.vessel_id);
    dispatch(clearVesselHistoryData());
    dispatch(setSelectedVessels([vessel as MyFleetVessel]));
    // If the set is now empty, clear vessel history data, clear the selected vessel, and set the dossier to NOTHING
    if (set.size === 0) {
      dispatch(clearVesselHistoryData());
      clearSelectedVessel();
      dispatch(setSelectedDossier(EDossiers.NOTHING));
    }
  } else {
    // otherwise toggle state of vessel and set the dossier to VESSEL
    dispatch(setSelectedVessels([vessel as MyFleetVessel]));
    dispatch(setSelectedDossier(EDossiers.VESSEL));
  }
}
namespace ManageFleetUtils {
  export const handleFleetVessels = (
    fleets: FleetVisibility[],
    dispatch: ReturnType<typeof useAppDispatch>
  ) => {
    const allVessels = fleets.flatMap((fleet) => fleet.list.vessels);
    const uniqueVessels: any[] = [];
    const seenKeys = new Set();

    allVessels.forEach((vessel) => {
      const key = `${vessel.vessel_name}-${vessel.imo}-${vessel.current_mmsi}-${vessel.vessel_type}`;
      if (!seenKeys.has(key)) {
        seenKeys.add(key);
        // Vessel can come from either static or dynamic source
        // This means the properties could have different names i.e mmsi or current_mmsi
        uniqueVessels.push({
          name: vessel.vessel_name ?? (vessel as any).name,
          imo: `${vessel.imo}`, // Need to cast to string as vessel won't appear on map if of number type.
          mmsi: vessel.current_mmsi ?? `${(vessel as any).mmsi}`,
          shipType: vessel.vessel_type ?? (vessel as any).shiptype,
        });
      }
    });

    const vesselsInSelectedLists = uniqueVessels;

    dispatch(
      setFleetsFilters({
        vesselListsVessels: vesselsInSelectedLists,
      })
    );
  };

  export const getVisibleVessels = (
    visibleFleets: FleetVisibility[],
    fleetsVessels: NormalisedFleetsVessels
  ): FleetsVessel[] => {
    const visibleVesselIds = visibleFleets.flatMap((fleet) =>
      fleet.list.vessels.map((vessel) => vessel.vessel_id)
    );
    return visibleVesselIds
      .map((vesselId) => fleetsVessels.byId[vesselId])
      .filter((vessel): vessel is FleetsVessel => vessel !== undefined);
  };

  export const focusOnSelectedVessel = (vessel: FleetsVessel) => {
    if (!vessel.latitude || !vessel.longitude) {
      return;
    }
    MapHelpers.zoomToPoint({ lat: vessel.latitude, lng: vessel.longitude }, 8);
  };

  export const handleListItemClick = (
    e: React.MouseEvent | null,
    vessel: FleetsVessel,
    dispatch: ReturnType<typeof useAppDispatch>
  ) => {
    if (
      vessel.latitude === undefined ||
      vessel.longitude === undefined ||
      vessel.vessel_id === undefined ||
      vessel.latitude === null ||
      vessel.longitude === null ||
      vessel.vessel_id === null
    ) {
      return;
    }

    const selectedVesselIds =
      store.getState().vesselDossier.selectedVessels?.allIds || [];

    const set = new Set(selectedVesselIds);

    if (e && e.ctrlKey) {
      handleMultiSelect(vessel as MyFleetVessel, set, dispatch);
    } else {
      handleSingleSelect(vessel as MyFleetVessel, set, dispatch);
      focusOnSelectedVessel(vessel);
    }
    dispatch(addHistoryItem({ type: 'fleets' }));
    updateMyFleetVesselLocationInStore(vessel as MyFleetVessel);
  };

  export const filterMatchingVessels = (
    list: FleetsListData,
    fleetsVesselsData: NormalisedFleetsVessels
  ) => {
    const { idsByImoMmsi, byId } = fleetsVesselsData;
    // Check if is_dynamic_list and then perform different match
    if (list.is_dynamic_list) {
      // Filter objects that match the vessel list id
      const obj = Object.values(byId).filter((vessel) =>
        vessel.vessel_list_id.includes(list.vessel_list_id)
      );
      return obj;
    }
    const vesselsMyfleetId = list.vessels
      .flatMap((listVessel) => {
        const imo = listVessel.imo ?? 'undefined';
        const mmsi = listVessel.current_mmsi ?? 'undefined';
        const keysToCheck = [
          `${imo}-${mmsi}`,
          `${imo}-undefined`,
          `undefined-${mmsi}`,
        ];

        return keysToCheck.reduce<string[]>((acc, key) => {
          if (idsByImoMmsi[key]) {
            acc.push(...idsByImoMmsi[key]);
          }
          return acc;
        }, []);
      })
      .filter((id): id is string => id !== null && id !== undefined);

    const uniqueVesselIds = Array.from(new Set(vesselsMyfleetId));

    return uniqueVesselIds.map((vesselId) => byId[vesselId]);
  };

  export const goToFleetView = (
    list: FleetsListData,
    dispatch: ReturnType<typeof useAppDispatch>,
    fleetsVessels: NormalisedFleetsVessels | null
  ) => {
    if (!fleetsVessels) {
      return;
    }
    if (list.vessels.length > 0 || list.is_dynamic_list) {
      dispatch(toggleFleetVisibility({ visible: true, list }));
    }
    const matchingVessels = filterMatchingVessels(list, fleetsVessels);

    dispatch(
      setSelectedFleet({
        listName: list.list_name,
        selectedList: list,
        vessels: matchingVessels,
      })
    );
  };

  export const getNonEmptyVisibleFleetIds = (
    visibleFleetIds: FleetVisibility[]
  ) =>
    visibleFleetIds.filter((fleet) => {
      const fleetVessels = fleet.list?.vessels || [];
      return fleetVessels.length > 0;
    });

  export const updateMapLayersForEmptyFleets = (
    dispatch: ReturnType<typeof useAppDispatch>
  ) => {
    setVesselFeatures(MapLayer.MY_FLEET_VESSELS, []);
    dispatch(setVisibleVessels([]));
    MapHelpers.setLayerVisibilityIfExists(MapLayer.MY_FLEET_VESSELS, false);
  };

  export const updateMapLayersForNonEmptyFleets = (
    fleetsVessels: NormalisedFleetsVessels,
    filters: FleetsFilters,
    dispatch: ReturnType<typeof useAppDispatch>,
    canAccessFleetsPhase2?: boolean,
    visibleFleets?: FleetVisibility[]
  ) => {
    let filteredVessels: Vessel[] = [];
    if (canAccessFleetsPhase2 && visibleFleets) {
      filteredVessels = getVisibleVessels(
        visibleFleets,
        fleetsVessels
      ) as Vessel[];
    } else {
      filteredVessels = applyVesselsFilter(
        fleetsVessels
          ? (Object.values(fleetsVessels.byId) as MyFleetVessel[])
          : [],
        filters
      );
    }
    dispatch(setVisibleVessels(filteredVessels));
    setVesselFeatures(MapLayer.MY_FLEET_VESSELS, filteredVessels);
    MapHelpers.setLayerVisibilityIfExists(MapLayer.MY_FLEET_VESSELS, true);
    MapHelpers.moveLayerIfExists(MapLayer.MY_FLEET_VESSELS);
  };

  export const getMatchingVessel = (
    vessels: FleetsListVessel[],
    vesselIds: string[]
  ) => vessels.filter((vessel) => vesselIds.includes(vessel.vessel_id));

  export const handleDeleteIconClick = (
    dispatch: ReturnType<typeof useAppDispatch>,
    selectedFleet: SelectedFleet | null,
    selectedVessels: string[]
  ) => {
    if (!selectedFleet) return;
    const matchingVessels = getMatchingVessel(
      selectedFleet.selectedList.vessels,
      selectedVessels
    );
    if (!matchingVessels || matchingVessels.length === 0) return;
    const vesselIds = matchingVessels.map(
      (vessel) => (vessel as FleetsListVessel).vessel_id
    );
    const vesselListOwner = selectedFleet.selectedList.is_company_list
      ? VesselListOwnershipType.Company
      : VesselListOwnershipType.Personal;
    const fleetType = selectedFleet.selectedList.is_dynamic_list
      ? FleetType.Dynamic
      : FleetType.Static;
    DeleteFleetsDialogUtils.openDeleteFleetDialog(
      dispatch,
      selectedFleet.listName,
      selectedFleet.selectedList.vessel_list_id,
      vesselListOwner,
      fleetType,
      matchingVessels as FleetsListVessel[],
      vesselIds
    );
  };

  export const handleMasterCheckboxChange = (
    dispatch: ReturnType<typeof useAppDispatch>,
    event: { target: { checked: any } },
    selectedFleet: SelectedFleet | null
  ) => {
    if (!selectedFleet) return;
    const isChecked = event.target.checked;

    dispatch(
      setCheckedVesselsIds(
        isChecked ? selectedFleet.vessels.map((vessel) => vessel.vessel_id) : []
      )
    );
  };

  export const handleIndividualCheckboxChange = (
    dispatch: ReturnType<typeof useAppDispatch>,
    vessel: FleetsVessel,
    selectedFleet: SelectedFleet | null,
    checkedVessels: string[]
  ) => {
    if (!selectedFleet) return;

    const newChecked = checkedVessels.includes(vessel.vessel_id)
      ? checkedVessels.filter((v) => v !== vessel.vessel_id)
      : [...checkedVessels, vessel.vessel_id];

    dispatch(setCheckedVesselsIds(newChecked));
  };

  export const mergeFleets = (
    accumulatedFleets: FleetsListData[],
    newFleets: FleetsListData[]
  ): FleetsListData[] => {
    const fleetMap = new Map<string, FleetsListData>();
    accumulatedFleets.forEach((fleet) => {
      fleetMap.set(fleet.vessel_list_id, fleet);
    });
    newFleets.forEach((fleet) => {
      if (fleetMap.has(fleet.vessel_list_id)) {
        const existingFleet = fleetMap.get(fleet.vessel_list_id);
        if (existingFleet) {
          existingFleet.vessels = [...existingFleet.vessels, ...fleet.vessels];
        }
      } else {
        fleetMap.set(fleet.vessel_list_id, fleet);
      }
    });

    return Array.from(fleetMap.values());
  };

  export const fetchPage = async (
    page: number,
    accumulatedFleets: FleetsListData[] = []
  ): Promise<{ fleets: FleetsListData[] }> => {
    const response = await getFleetsVessels(page);
    const { data: fleetsLists, next_page: nextPage } = response;
    const transformedData: FleetsListData[] = Object.entries(fleetsLists).map(
      ([key, fleet]) => ({
        vessel_list_id: key,
        ...fleet,
      })
    );
    const updatedFleets: FleetsListData[] = mergeFleets(
      accumulatedFleets,
      transformedData
    );

    if (nextPage !== null) {
      return fetchPage(nextPage, updatedFleets);
    }

    return { fleets: updatedFleets };
  };

  export const fetchFleets = async (
    dispatch: ReturnType<typeof useAppDispatch>
  ): Promise<{ success: boolean; error?: any }> => {
    dispatch(setFleetsLoading(true));
    try {
      const { fleets } = await fetchPage(1);
      const personalFleets = fleets.filter((fleet) => !fleet.is_company_list);
      const companyFleets = fleets.filter((fleet) => fleet.is_company_list);

      dispatch(
        setPersonalFleets(
          personalFleets.sort((a, b) => a.list_name.localeCompare(b.list_name))
        )
      );
      dispatch(
        setCompanyFleets(
          companyFleets.sort((a, b) => a.list_name.localeCompare(b.list_name))
        )
      );
      return { success: true };
    } catch (error) {
      return { success: false, error };
    } finally {
      dispatch(protectCompanyFleet());
      dispatch(updateCurrentFleetNames());
      dispatch(setFleetsLoading(false));
    }
  };

  export const convertIntoFleetsVessel = (
    vessel: VesselData,
    vessel_list_id: string
  ): FleetsVessel => ({
    name: vessel.staticData.name,
    flag: vessel.staticData.flag,
    vessel_id: vessel.vesselId,
    vessel_list_id: [vessel_list_id],
    imo: vessel.staticData.imo,
    mmsi: vessel.staticData.mmsi,
    latitude: vessel.lastPositionUpdate.position.coordinates[1],
    longitude: vessel.lastPositionUpdate.position.coordinates[0],
    callsign: vessel.staticData.callsign,
    shiptype: vessel.staticData.shipType,
    source: VesselSource.AIS,
  });

  export const fetchAndSetFleetVessels = async (
    personalFleets: FleetsListData[],
    companyFleets: FleetsListData[],
    dispatch: ReturnType<typeof useAppDispatch>
  ) => {
    if (personalFleets.length > 0 || companyFleets.length > 0) {
      dispatch(setFleetsLoading(true));
      dispatch(setFleetsVesselsLoading(true));
      dispatch(setFleetsVesselsError(false));

      const allLists = [...personalFleets, ...companyFleets];
      const listVesselsPromises = allLists.map(async (list: FleetsListData) => {
        dispatch(
          setFleetVesselsLoading({ [list.vessel_list_id.toString()]: true })
        );
        if (list.is_dynamic_list) {
          const dataCallback = (
            data: FleetsVessel[],
            fleetsData: FleetsListVessel[],
            loading: boolean
          ) => {
            dispatch(
              setFleetVesselsLoading({
                [list.vessel_list_id.toString()]: loading,
              })
            );
            const addPayload = {
              vessels: fleetsData,
              fleetId: list.vessel_list_id,
            };
            if (list.is_company_list) {
              dispatch(addVesselsToCompanyFleet(addPayload));
            } else {
              dispatch(addVesselsToPersonalFleet(addPayload));
            }
            dispatch(setFleetsVessels(data));
          };
          return getDynamicVesselsDAASWithLocation({
            dataCallback,
            limit: 1000,
            offset: 0,
            flags: list.filters?.flags,
            shipTypes: list.filters?.shipTypes,
            vessel_list_id: list.vessel_list_id,
          });
        }
        return getVesselsWithLocationsForFleet(
          list.vessels,
          list.vessel_list_id
        )
          .then((vessels: FleetsVessel[]) => {
            dispatch(
              setFleetVesselsLoading({
                [list.vessel_list_id.toString()]: false,
              })
            );
            dispatch(setFleetsVessels(vessels));
          })
          .catch(() => {
            dispatch(setFleetsVesselsError(true));
          });
      });
      await Promise.all(listVesselsPromises);
      dispatch(setFleetsVesselsLoading(false));
      dispatch(setCompletedFetchLists(false));
      dispatch(setFleetsLoading(false));
    }
  };

  export const handleConfirmFleetError = (
    dispatch: ReturnType<typeof useAppDispatch>,
    errorMessage: string
  ) => {
    dispatch(setManageFleetVesselsErrorMessage(errorMessage));
    dispatch(setManageFleetVesselsError(true));
    dispatch(setManageFleetVesselsLoading(false));
    return null;
  };

  export const createStaticFleetFromGlobalAis = async (
    dispatch: ReturnType<typeof useAppDispatch>,
    fleetName: string,
    hideVesselsByType: GlobalAisShipType[],
    hideFlags: string[]
  ): Promise<PatchFleetsVesselsResponse | null> => {
    dispatch(setManageFleetVesselsSuccess(false));
    dispatch(setManageFleetVesselsError(false));
    dispatch(
      setManageFleetVesselsLoadingMessage(
        FleetStatusMessages.PROCESSING_VESSELS_MESSAGE // update message to display new static fleet creation message
      )
    );
    dispatch(setManageFleetVesselsLoading(true));
    dispatch(setConfirmFleetVesselsOpen(true));
    if (
      !fleetName ||
      (hideVesselsByType.length === 0 && hideFlags.length === 0)
    ) {
      dispatch(setConfirmFleetVessels([]));
      dispatch(setManageFleetVesselsLoading(false));
      return null;
    }

    try {
      const transformedHideVesselsByType = hideVesselsByType
        .map((type) => globalAisToDaasShipType(type))
        .flat();
      const results = await getDynamicVesselsDAAS({
        flags: hideFlags,
        shipTypes: transformedHideVesselsByType,
      });
      if (results.length === 0) {
        return null;
      }
      const fleetFormItems: FleetsFormItem[] = results.map((vessel) => ({
        mmsi: vessel.staticData.mmsi
          ? vessel.staticData.mmsi.toString()
          : undefined,
        imo: vessel.staticData.imo
          ? vessel.staticData.imo.toString()
          : undefined,
      }));
      const imoMmsiVesselsResponse = await getImoMmsiVessels(fleetFormItems);
      if (imoMmsiVesselsResponse.length === 0) {
        dispatch(setConfirmFleetVessels([]));
        dispatch(setManageFleetVesselsLoading(false));
        return null;
      }

      const convertVesselDataToFleetsEndpointValuesResponse =
        convertVesselDataToFleetsEndpointValues(imoMmsiVesselsResponse);

      dispatch(
        setConfirmFleetVessels(convertVesselDataToFleetsEndpointValuesResponse)
      );
      dispatch(setManageFleetVesselsLoading(false));
      return null;
    } catch (error) {
      handleConfirmFleetError(
        dispatch,
        FleetStatusMessages.ERROR_RETRIEVING_VESSEL_DATA_MESSAGE
      );
      return null;
    }
  };

  export const createDynamicFleetFromGlobalAis = async (
    dispatch: ReturnType<typeof useAppDispatch>,
    fleetName: string,
    hideVesselsByType: GlobalAisShipType[],
    hideFlags: string[],
    vesselListOwner: VesselListOwnershipType
  ) => {
    const transformedHideVesselsByType = hideVesselsByType
      .map((type) => globalAisToDaasShipType(type))
      .flat();
    const filters = {
      shipTypes: transformedHideVesselsByType,
      flags: hideFlags,
    };
    const description = undefined; // replace when this is added to ui
    try {
      await postVesselList(
        fleetName,
        vesselListOwner,
        FleetType.Dynamic,
        description,
        filters
      );
      dispatch(setManageFleetVesselsSuccess(true));
      dispatch(setManageFleetVesselsLoading(false));
      dispatch(setCreatedDynamicFleetName(fleetName));
    } catch (error) {
      handleConfirmFleetError(
        dispatch,
        FleetStatusMessages.ERROR_CREATING_FLEET_MESSAGE
      );
    }
  };

  export const getFormVesselDetails = async (
    dispatch: ReturnType<typeof useAppDispatch>,
    imoMmsiPairs: FleetsFormItem[]
  ): Promise<PatchFleetsVesselsResponse | null> => {
    dispatch(setManageFleetVesselsSuccess(false));
    dispatch(setManageFleetVesselsError(false));
    dispatch(
      setManageFleetVesselsLoadingMessage(
        FleetStatusMessages.PROCESSING_VESSELS_MESSAGE
      )
    );
    dispatch(setManageFleetVesselsLoading(true));
    dispatch(setConfirmFleetVesselsOpen(true));
    if (imoMmsiPairs.length === 0) {
      dispatch(setConfirmFleetVessels([]));
      dispatch(setManageFleetVesselsLoading(false));
      return null;
    }
    try {
      const imoMmsiVesselsResponse = await getImoMmsiVessels(imoMmsiPairs);
      if (imoMmsiVesselsResponse.length === 0) {
        dispatch(setConfirmFleetVessels([]));
        dispatch(setManageFleetVesselsLoading(false));
        return null;
      }

      const convertVesselDataToFleetsEndpointValuesResponse =
        await convertVesselDataToFleetsEndpointValues(imoMmsiVesselsResponse);

      dispatch(
        setConfirmFleetVessels(convertVesselDataToFleetsEndpointValuesResponse)
      );
      dispatch(setManageFleetVesselsLoading(false));
      return null;
    } catch (error) {
      handleConfirmFleetError(
        dispatch,
        FleetStatusMessages.ERROR_RETRIEVING_VESSEL_DATA_MESSAGE
      );
      return null;
    }
  };

  export const addVesselsToFleet = async (
    dispatch: ReturnType<typeof useAppDispatch>,
    vessels: FleetsVesselBasic[]
  ): Promise<string[] | null> => {
    dispatch(setManageFleetVesselsLoading(true));
    dispatch(
      setManageFleetVesselsLoadingMessage(
        FleetStatusMessages.UPDATING_VESSEL_DETAILS_MESSAGE
      )
    );
    try {
      let totalProcessed = 0;
      let totalVesselIds: string[] = [];
      const totalVessels = vessels.length;
      const batchSize = 1000;

      const batches = Array(Math.ceil(totalVessels / batchSize))
        .fill(null)
        .map((_, index) => {
          const start = index * batchSize;
          return vessels.slice(start, start + batchSize);
        });

      await batches.reduce(async (previousPromise, batch) => {
        await previousPromise;
        const response = await postFleetsVessels(batch);
        totalVesselIds = totalVesselIds.concat(response.vessel_ids);
        totalProcessed += response.vessel_ids.length;
        dispatch(
          setManageFleetVesselsLoadingMessage(
            `Updated ${totalProcessed} of ${totalVessels} vessels ...`
          )
        );
      }, Promise.resolve());
      return totalVesselIds;
    } catch (error) {
      handleConfirmFleetError(
        dispatch,
        FleetStatusMessages.ERROR_ADDING_VESSELS_MESSAGE
      );
      return null;
    }
  };
}

export default ManageFleetUtils;
