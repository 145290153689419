export const validGlobalAisShipTypes = [
  'Cargo',
  'Fishing',
  'High Speed Craft',
  'Passenger',
  'Pleasure Craft',
  'Tanker',
  'Tugs And Special Craft',
  'Other',
] as const;

const validDaasShipTypes = [
  'FISHING',
  'SAILING',
  'PLEASURE_CRAFT',
  'GENERAL_CARGO',
  'OTHER',
  'PASSENGER',
  'GENERAL_TANKER',
  'DRY_BULK',
  'TUG',
  'PORT_TENDER',
  'TANKER_CHEMICALS',
  'HIGH_SPEED_CRAFT',
  'CONTAINER',
  'LAW_ENFORCEMENT',
  'MILITARY_OPS',
  'SEARCH_AND_RESCUE',
  'TANKER_PRODUCT',
  'VEHICLE_PASSENGER',
  'REEFER',
  'PILOT_VESSEL',
  'DREDGER',
  'OFFSHORE',
  'CAR_CARRIER',
  'TANKER_CRUDE',
  'DIVE_VESSEL',
  'ANTI_POLLUTION',
  'SPECIAL_CRAFT',
  'ROLL_ON_ROLL_OFF',
  'GAS_CARRIER',
  'LNG_CARRIER',
  'MEDICAL_TRANS',
  'LIVESTOCK',
  'COMBINATION_CARRIER',
] as const;
const validFrontendShipTypes = [
  'Fishing',
  'Sailing',
  'Pleasure Craft',
  'General Cargo',
  'Other',
  'Passenger',
  'General Tanker',
  'Dry Bulk',
  'Tug',
  'Port Tender',
  'Tanker Chemicals',
  'High Speed Craft',
  'Container',
  'Law Enforcement',
  'Military Ops',
  'Search and Rescue',
  'Tanker Product',
  'Vehicle Passenger',
  'Reefer',
  'Pilot Vessel',
  'Dredger',
  'Offshore',
  'Car Carrier',
  'Tanker Crude',
  'Dive Vessel',
  'Anti Pollution',
  'Special Craft',
  'Roll on Roll Off',
  'Gas Carrier',
  'LNG Carrier',
  'Medical Transport',
  'Livestock',
  'Combination Carrier',
] as const;

export type DaasShipType = typeof validDaasShipTypes[number];

export type FrontendShipType = typeof validFrontendShipTypes[number];

export type GlobalAisShipType = typeof validGlobalAisShipTypes[number];

export function daasToFrontendShipType(
  daasShipType: DaasShipType | string | null | undefined
): FrontendShipType {
  switch (daasShipType) {
    case 'FISHING':
      return 'Fishing';
    case 'SAILING':
      return 'Sailing';
    case 'PLEASURE_CRAFT':
      return 'Pleasure Craft';
    case 'GENERAL_CARGO':
      return 'General Cargo';
    case 'OTHER':
      return 'Other';
    case 'PASSENGER':
      return 'Passenger';
    case 'GENERAL_TANKER':
      return 'General Tanker';
    case 'DRY_BULK':
      return 'Dry Bulk';
    case 'TUG':
      return 'Tug';
    case 'PORT_TENDER':
      return 'Port Tender';
    case 'TANKER_CHEMICALS':
      return 'Tanker Chemicals';
    case 'HIGH_SPEED_CRAFT':
      return 'High Speed Craft';
    case 'CONTAINER':
      return 'Container';
    case 'LAW_ENFORCEMENT':
      return 'Law Enforcement';
    case 'MILITARY_OPS':
      return 'Military Ops';
    case 'SEARCH_AND_RESCUE':
      return 'Search and Rescue';
    case 'TANKER_PRODUCT':
      return 'Tanker Product';
    case 'VEHICLE_PASSENGER':
      return 'Vehicle Passenger';
    case 'REEFER':
      return 'Reefer';
    case 'PILOT_VESSEL':
      return 'Pilot Vessel';
    case 'DREDGER':
      return 'Dredger';
    case 'OFFSHORE':
      return 'Offshore';
    case 'CAR_CARRIER':
      return 'Car Carrier';
    case 'TANKER_CRUDE':
      return 'Tanker Crude';
    case 'DIVE_VESSEL':
      return 'Dive Vessel';
    case 'ANTI_POLLUTION':
      return 'Anti Pollution';
    case 'SPECIAL_CRAFT':
      return 'Special Craft';
    case 'ROLL_ON_ROLL_OFF':
      return 'Roll on Roll Off';
    case 'GAS_CARRIER':
      return 'Gas Carrier';
    case 'LNG_CARRIER':
      return 'LNG Carrier';
    case 'MEDICAL_TRANS':
      return 'Medical Transport';
    case 'LIVESTOCK':
      return 'Livestock';
    case 'COMBINATION_CARRIER':
      return 'Combination Carrier';
    default:
      return 'Other';
  }
}

export function frontendToDaasShipType(
  frontendShipType: FrontendShipType | null | undefined
): DaasShipType {
  switch (frontendShipType) {
    case 'Fishing':
      return 'FISHING';
    case 'Sailing':
      return 'SAILING';
    case 'Pleasure Craft':
      return 'PLEASURE_CRAFT';
    case 'General Cargo':
      return 'GENERAL_CARGO';
    case 'Other':
      return 'OTHER';
    case 'Passenger':
      return 'PASSENGER';
    case 'General Tanker':
      return 'GENERAL_TANKER';
    case 'Dry Bulk':
      return 'DRY_BULK';
    case 'Tug':
      return 'TUG';
    case 'Port Tender':
      return 'PORT_TENDER';
    case 'Tanker Chemicals':
      return 'TANKER_CHEMICALS';
    case 'High Speed Craft':
      return 'HIGH_SPEED_CRAFT';
    case 'Container':
      return 'CONTAINER';
    case 'Law Enforcement':
      return 'LAW_ENFORCEMENT';
    case 'Military Ops':
      return 'MILITARY_OPS';
    case 'Search and Rescue':
      return 'SEARCH_AND_RESCUE';
    case 'Tanker Product':
      return 'TANKER_PRODUCT';
    case 'Vehicle Passenger':
      return 'VEHICLE_PASSENGER';
    case 'Reefer':
      return 'REEFER';
    case 'Pilot Vessel':
      return 'PILOT_VESSEL';
    case 'Dredger':
      return 'DREDGER';
    case 'Offshore':
      return 'OFFSHORE';
    case 'Car Carrier':
      return 'CAR_CARRIER';
    case 'Tanker Crude':
      return 'TANKER_CRUDE';
    case 'Dive Vessel':
      return 'DIVE_VESSEL';
    case 'Anti Pollution':
      return 'ANTI_POLLUTION';
    case 'Special Craft':
      return 'SPECIAL_CRAFT';
    case 'Roll on Roll Off':
      return 'ROLL_ON_ROLL_OFF';
    case 'Gas Carrier':
      return 'GAS_CARRIER';
    case 'LNG Carrier':
      return 'LNG_CARRIER';
    case 'Medical Transport':
      return 'MEDICAL_TRANS';
    case 'Livestock':
      return 'LIVESTOCK';
    case 'Combination Carrier':
      return 'COMBINATION_CARRIER';
    default:
      return 'OTHER';
  }
}

export function globalAisToDaasShipType(
  globalAisShipType: GlobalAisShipType | null | undefined
): DaasShipType[] {
  switch (globalAisShipType) {
    case 'Cargo':
      return [
        'GENERAL_CARGO',
        'DRY_BULK',
        'CONTAINER',
        'CAR_CARRIER',
        'COMBINATION_CARRIER',
        'REEFER',
        'LIVESTOCK',
      ];
    case 'Fishing':
      return ['FISHING'];
    case 'High Speed Craft':
      return ['HIGH_SPEED_CRAFT'];
    case 'Passenger':
      return ['PASSENGER', 'VEHICLE_PASSENGER', 'ROLL_ON_ROLL_OFF'];
    case 'Pleasure Craft':
      return ['PLEASURE_CRAFT', 'SAILING'];
    case 'Tanker':
      return [
        'GENERAL_TANKER',
        'TANKER_CHEMICALS',
        'TANKER_PRODUCT',
        'TANKER_CRUDE',
        'GAS_CARRIER',
        'LNG_CARRIER',
      ];
    case 'Tugs And Special Craft':
      return [
        'TUG',
        'PORT_TENDER',
        'PILOT_VESSEL',
        'DIVE_VESSEL',
        'SPECIAL_CRAFT',
        'LAW_ENFORCEMENT',
        'MILITARY_OPS',
        'SEARCH_AND_RESCUE',
        'DREDGER',
        'OFFSHORE',
        'ANTI_POLLUTION',
        'MEDICAL_TRANS',
      ];
    case 'Other':
      return ['OTHER'];
    default:
      return ['OTHER'];
  }
}
