import dayjs from 'dayjs';
import { MapLayerMouseEvent, Popup } from 'mapbox-gl';
import { AlertLabels } from '../../../maritime-menu-options/alert-panel/alerting-vessels-labels';
import { AlertingVessel } from '../../../maritime-menu-options/alert-panel/alertingVessels.model';
import { EAlertTypes } from '../../../models/alerts/alert-configuration';
import PopupHelpers from '../../../popup/popup.utils';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';

const popup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideVesselInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  popup.remove();
}

export function showVesselInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  if (e.features?.length) {
    const feature = e.features[0];
    const properties = feature.properties as AlertingVessel;

    const {
      name,
      imo,
      callsign,
      shiptype,
      mmsi,
      speed,
      timestamp,
      alertTime,
      alertType,
    } = properties;

    const popupInfo = [
      {
        label: 'Name',
        value: name,
      },
      {
        label: 'IMO',
        value: imo,
      },
      {
        label: 'MMSI',
        value: mmsi,
      },
      {
        label: 'Vessel Type',
        value: shiptype
          ? shiptype
              .toLowerCase()
              .replaceAll('_', ' ')
              .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          : 'Unknown',
      },
      {
        label: 'Call Sign',
        value: callsign,
      },
      {
        label: 'Speed',
        value: `${speed} knots`,
      },
      {
        label: 'Timestamp',
        value: dayjs(timestamp).utc().format('YYYY-MM-DDTHH:mm:ss'),
      },
      {
        label: 'Hours Since Update',
        value: DateTimeHelpers.compareTimeInHours(
          new Date(timestamp),
          new Date()
        ),
      },
    ];

    if (alertType) {
      popupInfo.push({
        label: 'Alert Type',
        value: AlertLabels[alertType as EAlertTypes],
      });
    }

    if (alertTime) {
      popupInfo.push({
        label: 'Alert Time',
        value: alertTime,
      });
    }

    PopupHelpers.createInfoCard(popup, popupInfo);

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    popup.setLngLat(feature.geometry.coordinates);
    popup.addTo(e.target);
  }
}
