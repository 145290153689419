import { API } from '@aws-amplify/api';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const defaultInit = {
  headers: defaultHeaders,
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRequest = <T = any>(
  apiMethodapiName: 'get' | 'post' | 'put' | 'head' | 'del' | 'patch',
  apiName:
    | 'geonius'
    | 'maritime_cold'
    | 'maritime_hot'
    | 'mapbox'
    | 'capella'
    | 'atlas'
    | 'geospatial',
  apiPath: string,
  init: Record<string, unknown> = {}
): Promise<T> => {
  const mergeInit = { ...defaultInit, ...init };
  if (init.headers) {
    mergeInit.headers = { ...defaultHeaders, ...init.headers };
  }

  return API[apiMethodapiName](apiName, apiPath, mergeInit);
};
