/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MoveFleetState {
  moveFleetDialogOpen: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  fleetId: string;
  fleetName: string;
}

export const INITIAL_MOVE_FLEET_STATE: MoveFleetState = {
  moveFleetDialogOpen: false,
  loading: false,
  success: false,
  error: false,
  fleetId: '',
  fleetName: '',
};

const MoveFleetDialogSlice = createSlice({
  name: 'moveFleetDialog',
  initialState: INITIAL_MOVE_FLEET_STATE,
  reducers: {
    setMoveFleetDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.moveFleetDialogOpen = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setFleetId: (state, action: PayloadAction<string>) => {
      state.fleetId = action.payload;
    },
    setFleetName: (state, action: PayloadAction<string>) => {
      state.fleetName = action.payload;
    },
    setMoveFleetData: (
      state,
      action: PayloadAction<{ fleetId: string; fleetName: string }>
    ) => {
      state.fleetId = action.payload.fleetId;
      state.fleetName = action.payload.fleetName;
    },
    resetMoveFleetState: () => INITIAL_MOVE_FLEET_STATE,
  },
});

export const {
  setMoveFleetDialogOpen,
  setLoading,
  setSuccess,
  setError,
  setFleetId,
  resetMoveFleetState,
  setMoveFleetData,
} = MoveFleetDialogSlice.actions;

export default MoveFleetDialogSlice.reducer;
