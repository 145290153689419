/* eslint-disable no-console */
import {
  ExactMatchingVessel,
  FleetsFormItem,
  FleetsListResponse,
  FleetsShipType,
  FleetsVesselBasic,
  FleetType,
  PatchFleetsVesselsResponseBody,
  VesselListOwnershipType,
} from '../maritime-menu-options/fleets-panel/fleets.model';
import { DaasShipType } from '../models/vessels/all-ship-types';
import { VesselData } from '../models/vessels/maritime-ais-api';
import { wrapRequest } from './base';
import { dynamicVesselsTwoWeeks, getVesselsDAAS } from './vessels';

export interface PostFleetsVesselsResponse {
  vessel_ids: string[];
}

export interface GetFleetsVesselsResponse {
  data: FleetsListResponse;
  next_page: number | null;
}

export const postFleetsVessels = async (
  vessels: FleetsVesselBasic[]
): Promise<PostFleetsVesselsResponse> =>
  wrapRequest('post', 'geonius', `/fleets`, {
    body: { vessels, explain: false },
  });

export const patchFleetsVessels = async (
  fleetId: string,
  vesselIds: string[],
  replace: boolean = false,
  explain: boolean = false
): Promise<PatchFleetsVesselsResponseBody> =>
  wrapRequest('patch', 'geonius', `/fleets`, {
    body: {
      vessel_list_id: fleetId,
      vessel_ids: vesselIds,
      replace: replace.toString(),
      explain: explain.toString(),
    },
  });

export const getFleetsVessels = async (
  page: number
): Promise<GetFleetsVesselsResponse> =>
  wrapRequest('get', 'geonius', `/fleets?page=${page}`);

export const deleteFleet = async (
  fleetId: string,
  vesselListOwner: VesselListOwnershipType,
  fleetType: FleetType,
  fleetVesselIds?: string[]
): Promise<string> =>
  wrapRequest('del', 'geonius', `/fleets`, {
    body: {
      vessel_list_id: fleetId,
      vessel_ids: fleetVesselIds || [],
      vessel_list_type: vesselListOwner,
      fleet_type: fleetType,
    },
  });

export const getImoMmsiVessels = async (
  vesselsToUpload: FleetsFormItem[]
): Promise<VesselData[]> => {
  const imos: string[] = [];
  const mmsis: string[] = [];
  const exact: ExactMatchingVessel[] = [];

  vesselsToUpload.forEach((vessel) => {
    const { imo, mmsi } = vessel;

    if (imo && mmsi) {
      exact.push({ mmsi: parseInt(mmsi, 10), imo: parseInt(imo, 10) });
    } else {
      if (imo) {
        imos.push(imo);
      }
      if (mmsi) {
        mmsis.push(mmsi);
      }
    }
  });

  const startDatetime = (() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date.toISOString();
  })();

  const newVesselData = await getVesselsDAAS({
    imos,
    mmsis,
    exact,
    startDatetime,
  });

  return newVesselData;
};

export const convertVesselDataToFleetsEndpointValues = (
  vesselDataArray: VesselData[]
): FleetsVesselBasic[] => {
  const vesselMap = new Map<string, VesselData>();
  const mmsiSet = new Set<string>();

  vesselDataArray.forEach((vessel) => {
    if (!vessel.staticData.imo) {
      return;
    }

    const key = `${vessel.staticData.imo.toString()}-${vessel.staticData.mmsi.toString()}`;
    const existingVessel = vesselMap.get(key);
    if (
      !existingVessel ||
      new Date(vessel.lastUpdatedAt) > new Date(existingVessel.lastUpdatedAt)
    ) {
      vesselMap.set(key, vessel);
    }
    mmsiSet.add(vessel.staticData.mmsi.toString());
  });

  return Array.from(vesselMap.values()).map((vessel) => ({
    imo: vessel.staticData.imo.toString(),
    mmsi: vessel.staticData.mmsi.toString(),
    name: vessel.staticData.name,
    flag: vessel.staticData.flag,
    callsign: vessel.staticData.callsign,
    shipType:
      (vessel.staticData.shipType as FleetsShipType) || FleetsShipType.OTHER,
  }));
};

interface EstimateDynamicVesselsResponse {
  data: [];
  metadata: {
    count: number;
  };
}

/**
 * Estimates the number of dynamic vessels based on the provided ship types and flags.
 *
 * @param {DaasShipType[]} [shipTypes] - An optional array of ship types to filter the vessels, expected as 'GENERAL_CARGO' etc.
 * @param {string[]} [flags] - An optional array of flags to filter the vessels.
 * @returns {Promise<number>} A promise that resolves to the count of dynamic vessels.
 */
export const estimateDynamicVessels = async (
  shipTypes?: DaasShipType[],
  flags?: string[]
): Promise<number> => {
  const response = await wrapRequest<EstimateDynamicVesselsResponse>(
    'post',
    'geonius',
    `/ais-data`,
    {
      body: {
        shipTypes,
        flags,
        endpointSelection: 'vessels',
        returnCount: true,
        lastUpdatedSince: dynamicVesselsTwoWeeks,
      },
    }
  );
  return response.metadata.count;
};
