/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Point, Polygon } from '@turf/helpers';
import {
  AvcsConfigFormValues,
  defaultAvcsConfigFormValues,
} from './avcs-config/avcs-config-form';
import { AVCSDisplayParam } from './avcs-config/avcs-config.model';
import {
  AvcsCartItem,
  AvcsCatalogue,
  AvcsHolding,
  AvcsLayerOptions,
  AvcsMapToken,
  AvcsOrder,
} from './avcs.model';

interface AvcsState {
  loading: boolean;
  catalogueLoading: boolean;
  error: boolean;
  avcsLicenseId: number | null;
  avcsToken: AvcsMapToken | null;
  avcsMapUrl: string | null;
  avcsBaseUrl: string | null;
  avcsChecked: boolean;
  readmeChecked: boolean;
  avcsEulaAccepted: boolean;
  displayParams: AVCSDisplayParam[];
  avcsStandardLayerOptions: AvcsLayerOptions;
  avcsOtherLayerOptions: AvcsLayerOptions;
  avcsConfigFormValues: AvcsConfigFormValues;
  avcsCatalogue: AvcsCatalogue | null;
  avcsCatalogueError: string | null;
  avcsCatalogueSearch: string;
  avcsSelectedChart: string | null | undefined;
  avcsCataloguePage: number;
  avcsCart: AvcsCartItem[];
  avcsCartTotal: number;
  avcsSearchArea: Feature<Point | Polygon> | null;
  avcsAccordianExpanded: boolean;
  avcsLayerOptionAccordions: string | null;
  avcsPriceMatch: boolean;
  processingOrder: boolean;
  orderSuccessResult: AvcsOrder[] | null; // TODO: Update to better handle the new order response
  orderErrorResult: string | null; // TODO: Update to better handle the new order response
  avcsCheckoutOpen: boolean;
  content: React.ReactNode | null;
  requestingSupInfo: boolean;
  avcsNearbyFeaturesActive: boolean;
  supInfoErrorResult: string | null;
  supInfoLoading: boolean;
  supInfo: string;
  avcsHoldings: AvcsHolding[] | null;
  avcsHoldingsError: string | null;
  avcsActiveTab: number;
  avcsEulaFetch: string | null;
  avcsCompanyLicensesTotal: number | null;
}

export const DEFAULT_AVCS_STANDARD_LAYER_OPTIONS: AvcsLayerOptions = {
  // Layer 0 commented out but left for info as always required
  // layer0: { name: `Display Base`, checked: true, value: 0 },
  layer1: { name: `Drying line`, checked: true, value: 1 },
  layer2: {
    name: `Buoys, beacons, structures, aids to navigation`,
    checked: true,
    value: 2,
  },
  layer3: { name: `Lights`, checked: true, value: 3 },
  layer4: { name: `Boundaries and limits`, checked: true, value: 4 },
  layer5: { name: `Traffic lanes`, checked: true, value: 5 },
  layer6: { name: `Chart scale boundaries`, checked: true, value: 6 },
  layer7: { name: `Cautionary notes`, checked: true, value: 7 },
  layer8: {
    name: `Ships' routeing systems and ferry routes`,
    checked: true,
    value: 8,
  },
  layer9: { name: `Archipelagic sea lanes`, checked: true, value: 9 },
  layer10: { name: `Standard Miscellaneous`, checked: true, value: 10 },
};

export const DEFAULT_AVCS_OTHER_LAYER_OPTIONS: AvcsLayerOptions = {
  layer11: { name: `Spot soundings`, checked: false, value: 11 },
  layer12: {
    name: `Submarine cables and pipelines`,
    checked: false,
    value: 12,
  },
  layer13: { name: `All isolated dangers`, checked: false, value: 13 },
  layer14: { name: `Magnetic variation`, checked: false, value: 14 },
  layer15: { name: `Depth contours`, checked: false, value: 15 },
  layer16: { name: `Seabed`, checked: false, value: 16 },
  layer17: { name: `Tidal`, checked: false, value: 17 },
  layer18: { name: `Low accuracy symbol`, checked: false, value: 18 },
  layer19: { name: `Shallow Water Pattern`, checked: false, value: 19 },
  layer20: { name: `Other Miscellaneous`, checked: false, value: 20 },
  layer21: {
    name: `Admiralty Information Overlay`,
    checked: false,
    value: 21,
  },
  layer22: { name: `Data Quality Symbols/Marks`, checked: false, value: 22 },
};

export const AVCS_TOKEN_LOCAL_STORAGE_KEY = 'avcs_token';
export const AVCS_MAP_URL_LOCAL_STORAGE_KEY = 'avcs_map_url';
export const AVCS_BASE_URL_LOCAL_STORAGE_KEY = 'avcs_base_url';
export const AVCS_CHECKED_LOCAL_STORAGE_KEY = 'avcs_checked';
export const AVCS_STANDARD_LAYER_OPTIONS_LOCAL_STORAGE_KEY =
  'avcs_standard_layer_options';
export const AVCS_OTHER_LAYER_OPTIONS_LOCAL_STORAGE_KEY =
  'avcs_other_layer_options';
export const AVCS_CONFIG_FORM_VALUES_LOCAL_STORAGE_KEY =
  'avcs_config_form_values';

export const INITIAL_AVCS_STATE: AvcsState = {
  loading: false,
  catalogueLoading: false,
  error: false,
  avcsLicenseId: null,
  avcsToken: JSON.parse(
    localStorage.getItem(AVCS_TOKEN_LOCAL_STORAGE_KEY) || JSON.stringify(null)
  ),
  avcsMapUrl: JSON.parse(
    localStorage.getItem(AVCS_MAP_URL_LOCAL_STORAGE_KEY) || JSON.stringify(null)
  ),
  avcsBaseUrl: JSON.parse(
    localStorage.getItem(AVCS_BASE_URL_LOCAL_STORAGE_KEY) ||
      JSON.stringify(null)
  ),
  avcsChecked: JSON.parse(
    localStorage.getItem('avcsChecked') || JSON.stringify(false)
  ),
  avcsEulaAccepted: false,
  displayParams: [],
  avcsStandardLayerOptions: DEFAULT_AVCS_STANDARD_LAYER_OPTIONS,
  avcsOtherLayerOptions: DEFAULT_AVCS_OTHER_LAYER_OPTIONS,
  avcsConfigFormValues: JSON.parse(
    localStorage.getItem(AVCS_CONFIG_FORM_VALUES_LOCAL_STORAGE_KEY) ||
      JSON.stringify(defaultAvcsConfigFormValues)
  ),
  avcsCatalogue: null,
  avcsCatalogueError: null,
  avcsCatalogueSearch: '',
  avcsSelectedChart: null,
  avcsCataloguePage: 1,
  avcsCart: [],
  avcsCartTotal: 0,
  avcsSearchArea: null,
  avcsAccordianExpanded: false,
  avcsLayerOptionAccordions: null,
  avcsPriceMatch: false,
  processingOrder: false,
  orderSuccessResult: null,
  orderErrorResult: null,
  avcsCheckoutOpen: false,
  content: null,
  requestingSupInfo: false,
  avcsNearbyFeaturesActive: false,
  readmeChecked: false,
  supInfoErrorResult: null,
  supInfoLoading: false,
  supInfo: '',
  avcsHoldings: null,
  avcsHoldingsError: null,
  avcsActiveTab: 0,
  avcsEulaFetch: null,
  avcsCompanyLicensesTotal: null,
};

export const AvcsSlice = createSlice({
  name: 'avcs',
  initialState: INITIAL_AVCS_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCatalogueLoading: (state, action: PayloadAction<boolean>) => {
      state.catalogueLoading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setSupInfoErrorResult: (state, action: PayloadAction<string | null>) => {
      state.supInfoErrorResult = action.payload;
    },
    setSupInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.supInfoLoading = action.payload;
    },
    setAvcsLicenseId: (state, action: PayloadAction<number | null>) => {
      state.avcsLicenseId = action.payload;
    },
    setAvcsToken: (state, action: PayloadAction<AvcsMapToken>) => {
      state.avcsToken = action.payload;
      localStorage.setItem(
        AVCS_TOKEN_LOCAL_STORAGE_KEY,
        JSON.stringify(action.payload)
      );
      state.loading = false;
    },
    setAvcsMapUrl: (state, action: PayloadAction<string>) => {
      state.avcsMapUrl = action.payload;
      localStorage.setItem(
        AVCS_MAP_URL_LOCAL_STORAGE_KEY,
        JSON.stringify(action.payload)
      );
      state.loading = false;
    },
    setAvcsBaseUrl: (state, action: PayloadAction<string>) => {
      state.avcsBaseUrl = action.payload;
      localStorage.setItem(
        AVCS_BASE_URL_LOCAL_STORAGE_KEY,
        JSON.stringify(action.payload)
      );
      state.loading = false;
    },
    setAvcsChecked: (state, action: PayloadAction<boolean>) => {
      state.avcsChecked = action.payload;
      localStorage.setItem(
        AVCS_CHECKED_LOCAL_STORAGE_KEY,
        JSON.stringify(action.payload)
      );
    },
    setReadmeChecked: (state, action: PayloadAction<boolean>) => {
      state.readmeChecked = action.payload;
    },
    setAvcsEulaAccepted: (state, action: PayloadAction<boolean>) => {
      state.avcsEulaAccepted = action.payload;
    },
    setDisplayParams: (state, action: PayloadAction<AVCSDisplayParam[]>) => {
      state.displayParams = action.payload;
    },
    setAvcsCatalogue: (state, action: PayloadAction<AvcsCatalogue | null>) => {
      state.avcsCatalogue = action.payload;
    },
    setAvcsCatalogueError: (state, action: PayloadAction<string | null>) => {
      state.avcsCatalogueError = action.payload;
    },
    setAvcsStandardLayerOptions: (
      state,
      action: PayloadAction<Partial<AvcsLayerOptions>>
    ) => {
      const { payload } = action;
      const filteredPayload: AvcsLayerOptions = {};

      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (value !== undefined) {
          filteredPayload[key] = value;
        }
      });

      state.avcsStandardLayerOptions = {
        ...state.avcsStandardLayerOptions,
        ...filteredPayload,
      };
    },
    setAvcsOtherLayerOptions: (
      state,
      action: PayloadAction<Partial<AvcsLayerOptions>>
    ) => {
      const { payload } = action;
      const filteredPayload: AvcsLayerOptions = {};

      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (value !== undefined) {
          filteredPayload[key] = value;
        }
      });

      state.avcsOtherLayerOptions = {
        ...state.avcsOtherLayerOptions,
        ...filteredPayload,
      };
    },
    setAvcsConfigFormValues: (
      state,
      action: PayloadAction<Partial<AvcsConfigFormValues> | null>
    ) => {
      state.avcsConfigFormValues = {
        ...state.avcsConfigFormValues,
        ...action.payload,
      };
      localStorage.setItem(
        AVCS_CONFIG_FORM_VALUES_LOCAL_STORAGE_KEY,
        JSON.stringify(state.avcsConfigFormValues)
      );
    },
    setAvcsCatalogueSearch: (state, action: PayloadAction<string>) => {
      state.avcsCatalogueSearch = action.payload;
    },
    setAvcsSelectedChart: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      state.avcsSelectedChart = action.payload;
    },
    setAvcsCataloguePage: (state, action: PayloadAction<number>) => {
      state.avcsCataloguePage = action.payload;
    },
    addToAvcsCart: (state, action: PayloadAction<AvcsCartItem>) => {
      const existingItemIndex = state.avcsCart.findIndex(
        (item) =>
          item.feature.properties.catalogue_number ===
          action.payload.feature.properties.catalogue_number
      );

      if (existingItemIndex !== -1) {
        state.avcsCart[existingItemIndex].price = action.payload.price;
        state.avcsCart[existingItemIndex].duration = action.payload.duration;
      } else {
        state.avcsCart.push(action.payload);
      }

      const newTotal = state.avcsCart.reduce(
        (acc, item) => acc + parseFloat(item.price.split('£')[1]),
        0
      );
      state.avcsCartTotal = newTotal;
    },
    removeFromAvcsCart: (state, action: PayloadAction<AvcsCartItem>) => {
      const itemToRemove = action.payload;
      state.avcsCart = state.avcsCart.filter(
        (item) =>
          item.feature.properties.catalogue_number !==
          itemToRemove.feature.properties.catalogue_number
      );
      const newTotal = state.avcsCart.reduce(
        (acc, item) => acc + parseFloat(item.price.split('£')[1]),
        0
      );
      state.avcsCartTotal = newTotal;
    },
    setAvcsSearchArea: (
      state,
      action: PayloadAction<Feature<Point | Polygon> | null>
    ) => {
      state.avcsSearchArea = action.payload;
    },
    setAvcsAccordianExpanded: (state, action: PayloadAction<boolean>) => {
      state.avcsAccordianExpanded = action.payload;
    },
    setAvcsPriceMatch: (state, action: PayloadAction<boolean>) => {
      state.avcsPriceMatch = action.payload;
    },
    setProcessingOrder: (state, action: PayloadAction<boolean>) => {
      state.processingOrder = action.payload;
    },
    // TODO: Update to better handle the new order response
    setOrderSuccessResult: (
      state,
      action: PayloadAction<AvcsOrder[] | null>
    ) => {
      state.orderSuccessResult = action.payload;
    },
    // TODO: Update to better handle the new order response
    setOrderErrorResult: (state, action: PayloadAction<string | null>) => {
      state.orderErrorResult = action.payload;
    },
    setAvcsCheckoutOpen: (state, action: PayloadAction<boolean>) => {
      state.avcsCheckoutOpen = action.payload;
    },
    setContent: (state, action: PayloadAction<React.ReactNode | null>) => {
      state.content = action.payload;
    },
    setRequestingSupInfo: (state, action: PayloadAction<boolean>) => {
      state.requestingSupInfo = action.payload;
    },
    setAvcsNearbyFeaturesActive: (state, action: PayloadAction<boolean>) => {
      state.avcsNearbyFeaturesActive = action.payload;
    },
    setAvcsLayerOptionAccordions: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.avcsLayerOptionAccordions = action.payload;
    },
    setSupInfo: (state, action: PayloadAction<string>) => {
      state.supInfo = action.payload;
    },
    setAvcsHoldings: (state, action: PayloadAction<AvcsHolding[] | null>) => {
      state.avcsHoldings = action.payload;
    },
    setAvcsHoldingsError: (state, action: PayloadAction<string | null>) => {
      state.avcsHoldingsError = action.payload;
    },
    setAvcsActiveTab: (state, action: PayloadAction<number>) => {
      state.avcsActiveTab = action.payload;
    },
    setavcsEulaFetch: (state, action) => {
      state.avcsEulaFetch = action.payload; // Update datetime from the response
    },
    setAvcsCompanyLicensesTotal: (state, action) => {
      state.avcsCompanyLicensesTotal = action.payload; // Update datetime from the response
    },
  },
});

export const {
  setLoading,
  setCatalogueLoading,
  setError,
  setAvcsLicenseId,
  setAvcsToken,
  setAvcsMapUrl,
  setAvcsBaseUrl,
  setAvcsChecked,
  setReadmeChecked,
  setAvcsEulaAccepted,
  setDisplayParams,
  setAvcsStandardLayerOptions,
  setAvcsOtherLayerOptions,
  setAvcsConfigFormValues,
  setAvcsCatalogue,
  setAvcsCatalogueError,
  setAvcsCatalogueSearch,
  setAvcsSelectedChart,
  setAvcsCataloguePage,
  addToAvcsCart,
  removeFromAvcsCart,
  setAvcsSearchArea,
  setAvcsAccordianExpanded,
  setAvcsPriceMatch,
  setProcessingOrder,
  setOrderSuccessResult,
  setOrderErrorResult,
  setAvcsCheckoutOpen,
  setContent,
  setRequestingSupInfo,
  setAvcsNearbyFeaturesActive,
  setAvcsLayerOptionAccordions,
  setSupInfoErrorResult,
  setSupInfoLoading,
  setSupInfo,
  setAvcsHoldings,
  setAvcsHoldingsError,
  setAvcsActiveTab,
  setavcsEulaFetch,
  setAvcsCompanyLicensesTotal,
} = AvcsSlice.actions;

export default AvcsSlice.reducer;
