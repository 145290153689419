const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID;
const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID;
const AWS_USER_POOL_WEBCLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID;
const GEONIUS_BACKEND_URL_ROOT = process.env.REACT_APP_GEONIUS_BACKEND_URL;
const MARITIME_COLD_URL_ROOT = process.env.REACT_APP_MARITIME_COLD_URL;
const MARITIME_HOT_URL_ROOT = process.env.REACT_APP_MARITIME_HOT_URL;
const ENTITY_DOCUMENTS_BUCKET =
  process.env.REACT_APP_ENTITY_DOCUMENTS_BUCKET_NAME;
const ATLAS_ENDPOINT_URL_ROOT = process.env.REACT_APP_ATLAS_ENDPOINT_URL_ROOT;
const GEOSPATIAL_ENDPOINT_URL_ROOT =
  process.env.REACT_APP_GEOSPATIAL_ENDPOINT_URL_ROOT;

const awsExports = {
  Auth: {
    identityPoolId: AWS_IDENTITY_POOL_ID, // REQUIRED - Amazon Cognito Identity Pool ID
    region: AWS_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: AWS_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: AWS_USER_POOL_WEBCLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  },
  API: {
    endpoints: [
      {
        name: 'maritime_cold',
        endpoint: MARITIME_COLD_URL_ROOT,
        service: 'execute-api',
        region: AWS_REGION,
      },
      {
        name: 'maritime_hot',
        endpoint: MARITIME_HOT_URL_ROOT,
        service: 'execute-api',
        region: AWS_REGION,
      },
      {
        name: 'geonius',
        endpoint: GEONIUS_BACKEND_URL_ROOT,
        service: 'execute-api',
        region: AWS_REGION,
      },
      {
        name: 'mapbox',
        endpoint: 'https://api.mapbox.com',
      },
      {
        name: 'atlas',
        endpoint: ATLAS_ENDPOINT_URL_ROOT,
      },
      {
        name: 'geospatial',
        endpoint: GEOSPATIAL_ENDPOINT_URL_ROOT,
        service: 'execute-api',
        region: AWS_REGION,
      },
    ],
  },
  Storage: {
    AWSS3: {
      // Set the default bucket.
      // If we want to use multiple buckets in the future, the Storage API methods also allow the bucket to be
      // specified e.g. Storage.get('key', { bucket: 'bucket name' })
      bucket: ENTITY_DOCUMENTS_BUCKET,
      region: AWS_REGION,
    },
  },
};

export default awsExports;
