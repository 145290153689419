import { DaasShipType } from '../../models/vessels/all-ship-types';
import {
  VesselSource,
  VesselV2FormItem,
} from '../../models/vessels/vessel.model';

export const MAXIMUM_FLEET_SIZE = 5000;

export interface PatchFleetsVesselsResponse {
  headers: {
    'Access-Control-Allow-Origin': string;
    'Content-Type': string;
  };
  statusCode: number;
  body: PatchFleetsVesselsResponseBody;
}
export interface PatchFleetsVesselsResponseBody {
  vessels_added: number | null;
  vessels_deleted: number | null;
}

export interface AmendFormikVals {
  replaceFleetVessels: boolean;
  vessels: VesselV2FormItem[];
}

export interface CreateFormikVals {
  isCompanyList: boolean;
  vessels: FleetsFormItem[];
}

export interface VesselList {
  list_name: string;
  vessel_list_id: string;
  vessels: FleetsVesselData[];
}

export enum VesselListOwnershipType {
  Personal = 'personal',
  Company = 'company',
}

export enum VesselListOwnershipText {
  Personal = 'Personal Fleets',
  Company = 'Company Fleets',
}

export enum AmendVesselTab {
  Add = 'Add Vessel',
  Remove = 'Remove Vessel',
}

export enum FleetType {
  Static = 'static',
  Dynamic = 'dynamic',
}

export interface SelectedFleet {
  listName: string;
  selectedList: FleetsListData;
  vessels: FleetsVessel[];
}

export interface FleetsVessel {
  name?: string;
  flag?: string;
  vessel_id: string;
  vessel_list_id: string | string[];
  imo?: number | string;
  mmsi: number | string;
  heading?: number;
  latitude?: number;
  longitude?: number;
  callsign?: string;
  shiptype?: string;
  course?: number;
  speed?: number;
  source: VesselSource;
  timestamp?: string | number;
}

export type NormalisedFleetsVessels = {
  byId: Record<string, FleetsVessel>;
  idsByImoMmsi: Record<string, string[]>;
  allIds: FleetsVessel['vessel_id'][];
};

export interface PresetVessel {
  imo?: string;
  mmsi?: string;
}

export interface ExactMatchingVessel {
  mmsi: number;
  imo: number;
}

export type VesselSourceFilter = {
  ais: boolean;
  riskIntelligence: boolean;
};

export type FleetsVesselMinimal = {
  name: string;
  imo: string;
  mmsi: string;
};

export type FleetVesselInfo = {
  imo: string;
  current_mmsi: string;
  vessel_name: string;
  vessel_type: string;
  vessel_id?: string;
};

export interface FleetsVesselFilter extends FleetsVesselMinimal {
  shipType?: FleetsShipType;
}

export interface FleetsVesselBasic extends FleetsVesselFilter {
  flag?: string;
  name: string;
  mmsi: string;
  imo: string;
  callsign?: string;
}

export interface FleetsVesselData extends FleetsVesselFilter {
  vessel_id: string;
  vessel_list_id: string;
}

export interface FleetVessel {
  shipType?: FleetsShipType;
  flag?: string | null;
  name?: string | null;
  mmsi?: string | null;
  imo: string;
  callsign?: string | null;
  current_mmsi?: string | null;
  vessel_name?: string | null;
  vessel_type?: string | null;
  vessel_id?: string | null;
}

export type FleetsList = {
  list_name: string;
  is_company_list: boolean;
  vessels: FleetsListVessel[];
};

export type FleetsListVessel = {
  current_mmsi: string;
  imo: string;
  vessel_id: string;
  vessel_list_id: string[];
  vessel_name: string;
  vessel_type: FleetsShipType;
};

export type FleetsListResponse = {
  [vesselListId: string]: {
    list_name: string;
    is_company_list: boolean;
    vessels: FleetsListVessel[];
    is_dynamic_list: false;
    description: null;
    filters: null;
  };
};

export type FleetsListData = {
  description: string | null;
  filters: {
    shipTypes?: DaasShipType[] | null;
    flags?: string[] | null;
  } | null;
  is_dynamic_list: boolean;
  vessel_list_id: string;
  list_name: string;
  is_company_list: boolean;
  vessels: FleetsListVessel[];
};

export type FleetsFilters = {
  vesselSource: VesselSourceFilter;
  search: string;
  open: boolean;
  vesselListsVessels: FleetsVesselFilter[];
};

export interface FleetVisibility {
  visible: boolean;
  list: FleetsListData;
}

export interface VesselVisibility {
  visible: boolean;
  vessel: FleetsVessel;
}

export interface FleetsFormItem {
  mmsi?: string;
  imo?: string;
}

export interface CsvFile {
  name: string;
  data: any[];
  error: boolean;
  loading: boolean;
  success: boolean;
}

export enum FleetsShipType {
  CARGO = 'Cargo',
  TANKER = 'Tanker',
  DIVE_VESSEL = 'Dive Vessel',
  SPECIAL_CRAFT = 'Special Craft',
  LNG_CARRIER = 'LNG Carrier',
  GENERAL_CARGO = 'General Cargo',
  LAW_ENFORCEMENT = 'Law Enforcement',
  HIGH_SPEED_CRAFT = 'High Speed Craft',
  COMBINATION_CARRIER = 'Combination Carrier',
  LIVESTOCK = 'Livestock',
  GENERAL_TANKER = 'General Tanker',
  VEHICLE_PASSENGER = 'Vehicle Passenger',
  SAILING = 'Sailing',
  FISHING = 'Fishing',
  SEARCH_AND_RESCUE = 'Search and Rescue',
  TANKER_CHEMICALS = 'Tanker Chemicals',
  REEFER = 'Reefer',
  PORT_TENDER = 'Port Tender',
  TANKER_CRUDE = 'Tanker Crude',
  DREDGER = 'Dredger',
  TANKER_PRODUCT = 'Tanker Product',
  PLEASURE_CRAFT = 'Pleasure Craft',
  OTHER = 'Other',
  OFFSHORE = 'Offshore',
  CONTAINER = 'Container',
  PILOT_VESSEL = 'Pilot Vessel',
  CAR_CARRIER = 'Car Carrier',
  GAS_CARRIER = 'Gas Carrier',
  DRY_BULK = 'Dry Bulk',
  MILITARY_OPS = 'Military Ops',
  ROLL_ON_ROLL_OFF = 'Roll on Roll Off',
  TUG = 'Tug',
  BULK_CARRIER = 'Bulk Carrier',
  RESEARCH = 'Research Vessel',
}
