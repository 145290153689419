import { VesselList } from '../../common-components/manage-vessel-lists/vessel-lists.model';
import {
  FleetType,
  VesselListOwnershipType,
} from '../../maritime-menu-options/fleets-panel/fleets.model';
import { DaasShipType } from '../../models/vessels/all-ship-types';
import { wrapRequest } from '../base';

// eslint-disable-next-line import/prefer-default-export
export const getVesselLists = async (
  companyId?: string
): Promise<VesselList[]> =>
  wrapRequest('get', 'geonius', '/vessel-lists', {
    queryStringParameters: companyId ? { company_id: companyId } : {},
  });

/**
 * Sends a POST request to create a new vessel list for legacy "My Fleet".
 *
 * @param list_name - The name of the vessel list to be created.
 * @param companyId - The target company for the vessel list creation.
 * @returns A promise that resolves with the response of the request.
 */
export const postVesselListMyFleet = async (
  list_name: string,
  companyId?: string
) =>
  wrapRequest('post', 'geonius', `/vessel-lists`, {
    body: {
      list_name,
      company_id: companyId,
      fleet_type: FleetType.Static, // we hardcode this as it's only for legacy "My Fleet"
    },
  });

type FilterFunctions = {
  shipTypes: DaasShipType[];
  flags: string[];
};

/**
 * Sends a POST request to create a new vessel list.
 * @param list_name - The name of the vessel list to be created.
 * @param vesselListOwner - The type of the vessel list to be created.
 * @param fleetType - The type of the fleet to be created.
 * @param description - OPTIONAL - The description of the vessel list to be created.
 * @param filters - OPTIONAL - The filters of the vessel list to be created.
 * @returns A promise that resolves with the response of the request.
 */
export const postVesselList = async (
  list_name: string,
  vesselListOwner: VesselListOwnershipType,
  fleetType: FleetType,
  description?: string,
  filters?: FilterFunctions
) =>
  wrapRequest('post', 'geonius', `/vessel-lists`, {
    body: {
      list_name,
      vessel_list_type: vesselListOwner,
      fleet_type: fleetType,
      description,
      filters,
    },
  });

export const deleteVesselLists = async (
  list_ids: string[]
): Promise<'Successfully deleted vessel list(s)'> =>
  wrapRequest('del', 'geonius', `/vessel-lists`, {
    body: {
      data: {
        list_ids,
      },
    },
  });

export const deleteFromList = async (list_id: string, vessel_ids: string[]) =>
  wrapRequest('put', 'geonius', `/vessel-lists/delete-vessels`, {
    body: {
      data: {
        list_id,
        vessel_ids,
      },
    },
  });

export const addToList = async (list_id: string, vessel_ids: string[]) =>
  wrapRequest('put', 'geonius', `/vessel-lists/add-vessels`, {
    body: {
      data: {
        list_id,
        vessel_ids,
      },
    },
  });

export const renameFleet = async (
  vessel_list_id: string,
  newName: string,
  vesselListOwner: VesselListOwnershipType,
  fleetType: FleetType
): Promise<string> =>
  wrapRequest('patch', 'geonius', '/vessel-lists', {
    body: {
      vessel_list_id,
      new_name: newName,
      vessel_list_type: vesselListOwner,
      fleet_type: fleetType,
    },
  });

export const upgradeToCompanyFleet = async (
  vessel_list_id: string,
  vesselListOwner: VesselListOwnershipType,
  fleetType: FleetType
): Promise<string> =>
  wrapRequest('patch', 'geonius', '/vessel-lists', {
    body: {
      vessel_list_id,
      vessel_list_type: vesselListOwner,
      fleet_type: fleetType,
      upgrade_to_company_list: 'true',
    },
  });
